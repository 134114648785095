.layout__height{
    height: 100vh;
}

.main_header_width{
    width:15vw;
}

.second_header_width{
    width: 85vw;
}

.main__header{
    position: fixed;
    padding: 5px 0px;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 0px !important;
    margin-left: 0px !important;
    /* min-height: 80vh; */
    width: inherit;
    min-width: 10vw;
    background: url("../images/footer-bg-img.png")
}

.main__logo{
    width: 50%;
    margin: auto;
    margin-top: 20px !important;
}

.text_wrap{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.header__bar>ul{
    padding: 20px 0px;
    text-decoration: none;
    list-style: none;
    color: white;
}

.header__bar>ul>li{ 
   margin: 20px 0px;
   padding: 8px 10px;
   text-align: start;  
   /* margin-right: 10px; */
   width: inherit;
}

.header__bar>ul>li>a{
    padding: 8px 20px;
    font-family:  "Calibri";
    letter-spacing: 1px;
    text-align: center;
    cursor: pointer;
    font-size: 22px;
    color: white;
}

.nav__background{
    background-color: var(--ms-second-color);
}

.active{
    background: var(--ms-second-color);
}

.header__bar>ul>li:hover{
  background-color: var(--ms-second-color);
  color: white;
}

/* ** header top css starts from Here **  */

.header_top{
    /* padding: 18px; */
    background-color: white;
    box-shadow: 5px 10px 18px red;
}

.header_top_button{
    transition: all 0.4s ease-in-out;
}

.header_top_button:hover{
    color: var(--ms-second-color);
    transform: scale(1.1);
}