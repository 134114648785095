.login__container{
    height: 100vh;
    width: 100vw;
    background-image: url("../images/dulles-g9f82c7f66_1920.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size:cover;
}

.login_shadow{
    width:inherit;
    height: inherit;
    background: linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.6));

}

.login__form{
    width: 25%;
}

.login__heading{
    color:white;
    font-size: 30px;
}

.login__error{
    color: red;
    background: linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6));
    padding: 0px;
    letter-spacing: 1px;
    margin-bottom: 0px;
    font-family: var(--roboto);
    font-size: 14px;

}

.login__forget{
    color:var(--ms-third-color);
    text-decoration: underline;
    cursor: pointer;
}

.login__modal .modal-content{
    /* background: linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.3)) !important; */
    background:transparent !important;

}