.rewardbar__nav>div{
    font-size: 14px !important;
}

.reward__header{
    width: 90%;
    margin: auto;
}

.reward__header>div{
    margin: 0px !important;
    cursor: pointer;
}

.reward_image_box{
    width: 200px;
    height: 200px;
  
}

.reward_image_box>img{
    width: 100%;
    height: 100%;
}

.reward_cupon_image{
   max-width: 400px;
   max-height: 400px;
}

.reward_cupon_image>img{
    width: 100%;
    height: 100%;
}

.reward-business{
    background-color: red   ;
}

.reward_business>div{
    margin-bottom:10px;
    
}

.reward_scroll{
    height: 75%;
    margin: auto;
    overflow-y: auto;
    overflow-x: hidden;
}