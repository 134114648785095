.home__container{
    background-color: var(--background-color);
}

.chart__container{
    background-color: var(--background-color);
    width: 95%;
    margin: auto;
}

.chart__component{
    width:550px;
}

.home__date__container{
    margin-left: 2.5%;
}

.home__date{
    font-size: 15px;
    font-family: var(--ms-third-font);
}

.home__input{
    padding: 10px 8px !important;
    border: 1px solid var(--background-color) !important;
}

.home__date__btn{
    padding: 6px 10px;
    width: 100px;

}    
.home__form{
    /* background-color: ; */

}   

.home__box__container{
    width: 500px;
}

.home__box__content>p{
    font-family: var(--roboto);
    color: var(--clay);
    font-weight:bolder;
    font-size: 30px;
}

.home__box__content>p>span{
    font-size:20px;
    margin-left: 4px;
}

.home__box__container>h3{
    font-size: 20px;
    color: var(--clay);
    margin: 4px 8px;
}

.home__box__container>p{
    margin: 0px 8px;
    color: var(--clay);
    font-size: 15px;
}

