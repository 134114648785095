.onboard__form{
    width: 30%;
    margin: auto;
}

.onboard__form>div{
    margin: 8px 0px;
    color:var(--clay)
}

.onboard__form>div>label{
    font-size: 15px;
}

.onboard__form>div>div>label{
    font-size: 15px;
}


.onboard__form>div>input{
    height: 35px;
}

.onboard__form>div>div>input{
    height: 35px;
}

.onboard__form>div>div>label{
    color:var(--clay);  
    font-weight:600;

}

.onboard__form>div>label{
    font-weight:600;
}
