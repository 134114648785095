.grid__font{
    font-size: 14px;
}

.facilities__table{
    width: 100%;
    height: 100%;
}

.facilites__main__container{
    height: calc(100vh - 62px);
    background-color: var(--background-color);
}

.facilities__container{
    width: 90%;
    height: 85%;
    background-color: var(--white);

}

.facilites__heading{
    width: inherit;

}

.facilites__heading>h3{
    cursor: pointer;
    color: var(--clay);
    font-family:var(--ms-third-font);
    font-weight: bolder;
    margin: 0px;
    padding-top: 12px;
    font-size: 16px;
}

.facilities__heading__nav{
  color: var(--ms-primary-color) !important;
}

.facilites__heading__nav::after{
    content: " ";
    border-bottom: 3px solid var(--ms-primary-color);
    width: 90%;
    margin: auto;
    padding-bottom: 12px;
    display: block;
}

.facilities__table__list{
    width: 95%;
    margin: auto;
}

.facilities__main__table{
    font-size: 16px;
    background-color: var(--ms-primary-color);
    color: white;
}

.facilities__main__table>div{
    font-family: var(--ms-third-font) !important;
    font-size: 16px;
    font-weight: normal;
}

.facilities__user__list{
    font-family: var(--ms-third-font);
    width: max-content !important;
    cursor: pointer;
    border: 2px solid var(--background-color) !important;
}

.facilities__user__container{
    width: fit-content;
    margin-left:3.5%;
}

.facilities__user__list>option{
    cursor: pointer;
}

.action_btn{
    padding: 4px 12px;
    background-color: red;
    color: white;
    border-radius: 4px;
    border:0px !important;
    transition: all 1s ease;
}

.action_btn:hover{
    color: white;
    background-color: darkred;
}

.action_btn_black{
    height: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: black;
    color: white;
    border-radius: 4px;
    border:0px !important;
    transition: all 1s ease;
}

.action_btn_black:hover{
    color: white;
    background-color:black;
}

.facilites__certificate{
    width: 160px;
    cursor: pointer;
    height: 30px;
}

.facilites__certificate>img{
    width: inherit;
     height: inherit;
}