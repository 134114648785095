.lookups__main__container{
    height: calc(100vh - 62px);
}

.lookups__container{
    width: 90%;
    height: 75%;
    background-color: var(--white);
}

.lookups__form{
    background-color: var(--white);
}

.lookups__table__list{
    height: 95%;
    width: 95%;
    overflow-x: hidden;
    overflow-y: hidden;

}

.lookups__main__table{
    background-color: var(--ms-primary-color);
    color: white;
}

.lookups__main__table>div{
    padding: 4px 0px;
    font-family: var(--ms-third-font) !important;
    font-size: 17px;
    font-weight: normal;
}

.lookups__table__items{
    height: inherit;
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
}

.lookups__list>div{
    font-family: var(--ms-third-font);
   font-size: 15px;
   padding: 4px 0px;
   color: var(--clay);
} 

.lookups__user__list{
    font-family: var(--ms-third-font);
    width: max-content !important;
    cursor: pointer;
    border: 2px solid var(--background-color) !important ;
}

.lookups__error{
    border: 2px solid darkred !important;
}