.kyc__main__container{ 
    height: calc(100vh - 62px);
    background-color: var(--background-color);
}

.kyc__container{
    width: 90%;
    height: 85%;
    background-color: var(--white);
}

.kyc__heading{
    width: inherit;
}

.kyc__heading>h3{
  cursor: pointer;
  color: var(--clay);
  font-family:var(--ms-third-font);
  font-weight: bolder;
  margin: 0px;
  padding-top: 12px;
  font-size: 18px;
}

.kyc__heading__click{
  color: var(--ms-primary-color) !important;
}

.kyc__heading__click::after{
    content: " ";
    border-bottom: 3px solid var(--ms-primary-color);
    width: 90%;
    margin: auto;
    padding-bottom: 12px;
    display: block;

  }

.kyc__table{
    width: 100%;
    height: 100%;
}
.kyc__table__list{
    height: 85%;
    width: 95%;
    margin: auto;
    overflow-y: auto;
    overflow-x: hidden;
}

.kyc__main__table{
    font-size: 16px;
    background-color: var(--ms-primary-color);
    color: white;
}

.kyc__main__table>div{
    font-family: var(--ms-third-font) !important;
    font-size: 17px;
    font-weight: normal;
}

.kyc__table__items>div{
    font-size: 16px;
    padding: 8px 0px;
}


.kyc__details__nav>li{
    cursor: pointer;
    padding: 15px 10px;
    border-radius: 0px;

}

.kyc__details__nav>li:hover{
    background-color: var(--ms-primary-color);
    color: white;
    border-radius: 0px;
    
}

.kyc__details__button{
    width: fit-content;
    font-family: var(--roboto);
    font-size: 12px;
    padding: 6px 10px !important;
}

.kycsubscription__heading{
    font-family: var(--ms-third-font) !important;
}